<template>
  <div class="page-content">
    <div class="page-content-header">
      <div class="header-box">
        <h1>{{ $t('main_account_1') }}</h1>
        <el-button type="primary" @click="toReg" size="big">{{
          $t('main_account_2')
        }}</el-button>
      </div>
    </div>
    <div class="page-content-item textCenter">
      <img class="wth100p" src="@/assets/img/index/accounts-zh-cn.webp" />
      <h2>{{ $t('main_account_3') }}</h2>
      <p>{{ $t('main_account_4') }}</p>
    </div>
    <div class="page-content-item">
      <img
        class="wth600"
        src="@/assets/img/index/foreignCurrencyAccounts.png"
      />

      <h2 class="mgt40">{{ $t('main_account_5') }}</h2>
      <p class="wth600">{{ $t('main_account_6') }}</p>
    </div>
    <div class="page-content-item pdl300">
      <img class="wth200" src="@/assets/img/index/currenxieCrossPlatform.png" />
      <h2 class="mgt40">{{ $t('main_account_7') }}</h2>
      <p class="wth600">{{ $t('main_account_8') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'account',
  methods: {
    toReg() {
      this.$router.push({ name: 'Email' })
    },
  },
}
</script>

<style scoped lang="scss">
.page-content {
  display: flex;
  flex-direction: column;

  .page-content-header {
    background: url(../../assets/img/index/banner.png) no-repeat center;
    height: 480px;

    .header-box {
      width: 960px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }

    h1 {
      color: #ffffff;
      width: 100%;
    }
  }

  .textCenter {
    text-align: center;
  }

  .page-content-item {
    width: 960px;
    margin: 0 auto;
    padding: 70px 0;

    .mgt40 {
      margin-top: 40px;
    }

    .wth100p {
      width: 100%;
    }

    .wth200 {
      width: 200px;
    }

    .wth600 {
      width: 600px;
    }
  }

  .pdl300 {
    padding-left: 330px;
  }
}
</style>
